import React from "react";

import PortalNode from "components/common/PortalNode";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane,faTrash } from "@fortawesome/free-solid-svg-icons";

import Classes from './Actions.module.scss';

const Actions = ({ onClose, message, onForward, onDelete }) => {
  if (!message) {
    return '';
  }

  return (
    <PortalNode onClick={onClose.bind(null, null)} >
      <div className={Classes.container}>
        <button className={Classes.actionBtn} onClick={onForward}>
          <span>Forward message</span>
          <FontAwesomeIcon icon={faPaperPlane}  />
        </button>
        <button className={Classes.actionBtn} onClick={onDelete}>
          <span>Delete</span>
          <FontAwesomeIcon icon={faTrash}  />
        </button>
      </div>
    </PortalNode>
  );
};

export default Actions;
