import React from "react";

import useSearch from "./useSearch";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";

import PlantNameInput from "./components/PlantNameInput";
import BirdFoodCheckbox from "./components/BirdFoodCheckbox";
import GrowthSpeedSelector from "./components/GrowthSpeedSelector";
import ZoneGrowthSelector from "./components/ZoneGrowthSelector";
import HostCheckbox from "./components/HostCheckbox";
import InvasiveCheckbox from "./components/InvasiveCheckbox";
import HazardCheckbox from "./components/HazardCheckbox";
import MammalFoodCheckbox from "./components/MammalFoodCheckbox";

import Classes from "./Search.module.scss";

const Search = () => {
  const { filter, errors, change, search, setFilterValue } = useSearch();
  const { t } = useTranslation();

  return (
    <div className={Classes.formLayout}>
      <Form
        className={`row m-0 text-left overflow-auto ${Classes.formBg}`}
        noValidate
      >
        <PlantNameInput
          value={filter.name}
          setValue={setFilterValue}
          onChange={change}
          onSearch={search}
          className={Classes.name}
        />
        <GrowthSpeedSelector
          setValue={setFilterValue}
          value={filter.growth_speed}
        />
        <ZoneGrowthSelector
          setValue={setFilterValue}
          value={filter.zone_growth}
        />

        <div className={`col-12 row ${Classes.checkboxes}`}>
          <MammalFoodCheckbox value={filter.is_mammal_food} onChange={change} />
          <InvasiveCheckbox value={filter.is_invasive} onChange={change} />
          <HazardCheckbox value={filter.is_hazard} onChange={change} />
          <HostCheckbox value={filter.is_host} onChange={change} />
          <BirdFoodCheckbox value={filter.is_bird_food} onChange={change} />
        </div>
      </Form>
      <div>
        {!!errors.emptyParams && (
          <div className={Classes.invalidSearch}>{errors.emptyParams}</div>
        )}
        <button onClick={search} className={Classes.search} type="submit">
          {t("showResults")}
        </button>
      </div>
    </div>
  );
};

export default Search;
