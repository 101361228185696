import { take, fork, put, call, cancel } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  updateAccountSuccess,
  updateAccountFailure,
} from "../actionCreators/account";
import { callApi } from "./callApi";
import { saveUser } from "helpers/localStorage";
import {
  showFailureNotification,
  showSuccessNotification,
} from "store/actionCreators/notification";
import { createCallAxios, methods } from "services/apiService";
import { url } from "helpers/util";

function* processUpdateAccountRequest({ accountId, updateParams }) {
  try {
    // const token = yield select(getToken);
    const params = {
      // token,
      member_id: accountId,
      fromApp: true,
      ...updateParams,
    };

    const response = yield call(createCallAxios, `${url("updateAccount")}/${accountId}`, methods.PUT, params);
    const { data } = response;
    if (!data.success) {
      return yield put(updateAccountFailure({ exception: data.message }));
    }

    yield put(updateAccountSuccess(data.result));
    saveUser(data.result);
    window.isAuthenticated = true;
  } catch (e) {
    let exception = "Something went wrong, try again!";
    if (e?.response?.data) {
      exception = e.response?.data?.message.message;
    }

    yield put(updateAccountFailure({ exception }));
  }
}

function* processResetPasswordRequest({ email, history }) {
  try {
    const params = {
      email,
      needs_reset_password: true,
      notify_by_email: true,
    };

    const response = yield call(callApi, "resetPassword", "POST", params);
    const { data } = response;
    if (!data.success) {
      return yield put(
        showFailureNotification(
          data?.message || "Something went wrong, try again!"
        )
      );
    }

    yield put(
      showSuccessNotification(
        "Your password has been updated!\nPlease check your email."
      )
    );
    history.push("/login");
  } catch (e) {
    let message = "";
    if (e?.response?.data) {
      message = e.response?.data?.message.message;
    }
    yield put(
      showFailureNotification(message || "Something went wrong, try again!")
    );
  }
}

export function* watchUpdateAccount() {
  let forked = null;
  while (true) {
    const action = yield take(actionTypes.UPDATE_ACCOUNT_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processUpdateAccountRequest, action);
  }
}

export function* watchResetPassword() {
  let forked = null;
  while (true) {
    const action = yield take(actionTypes.RESET_PASSWORD_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processResetPasswordRequest, action);
  }
}
