module.exports = {
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:3533/api",
  apiRoutes: {
    getAccounts: "/v1/account",
    getAccount: "/v1/account",
    createAccount: "/v1/account",
    updateAccount: "/v1/account",
    deleteAccount: "/v1/account",

    authenticate: "/v1/account/authenticate",

    linkAccountAndCustomer: "/v1/customer/link-account",
    unlinkAccountAndCustomer: "/v1/customer/unlink-account",

    getPlant: "/v1/plant",
    getPlants: "/v1/plant",
    createPlant: "/v1/plant",
    updatePlant: "/v1/plant",
    deletePlant: "/v1/plant",

    getPlantSection: "/v1/plant-section",
    getPlantSections: "/v1/plant-section/list-by-plant",
    createPlantSection: "/v1/plant-section",
    updatePlantSection: "/v1/plant-section",
    deletePlantSection: "/v1/plant-section",

    getContent: "/v1/content",
    getContents: "/v1/content",

    getContentsByPlantCount: "/v1/content/list-by-plants-count",
    getPlantGrowthZoneContent: "/v1/content/list-by-plant-growth-zone",

    getContentsByType: "/v1/content/list-by-type",
    getContentTypes: "/v1/content/types",
    createContent: "/v1/content",
    updateContent: "/v1/content",
    deleteContent: "/v1/content",

    uploadImages: "/v1/media/upload-file",

    deleteSectionImage: "/v1/plant-section/image",
    updateSectionImageInfo: "/v1/plant-section/image",

    deleteContentImage: "/v1/content/image",
    updateContentImageInfo: "/v1/content/image",


    getCustomer: "/v1/customers",
    getCustomers: "/v1/customers",
    createCustomer: "/v1/customers",
    updateCustomer: "/v1/customers",
    deleteCustomer: "/v1/customers",
    getPlantCustomers: "/v1/customer/list-by-plant",
    linkPlantToCustomer: "/v1/customer/link-plant",
    unlinkPlantFromCustomer: "/v1/customer/unlink-plant",


    getRegion: "/v1/region",
    getRegions: "/v1/region",
    createRegion: "/v1/region",
    updateRegion: "/v1/region",
    deleteRegion: "/v1/region",
    getPlantRegions: "/v1/region/by-plant",

    resetPassword: "/v1/account/reset-password",

    // game related apis
    getQuizByCustomer: "/v1/quiz/list-by-customer",
    getQuestionsByLevel: "/v1/quiz/level",

    getQuestion: "/v1.quiz-question/get-one-with-answers",


    getQuiz: "/v1/quiz",
    resetQuiz: "/v1/quiz/reset",
    submitQuiz: "/v1/quiz/submit",
    submitQuizAnswer: "/v1/quiz-question/take",
    skipQuestion: "/v1/quiz-question/skip",
    getQuizTakenByLevel: "/v1/quiz/get-taken-quiz-by-level",
    getLeaderboard: "/v1/quiz/leaderboard",
    getQuizContent: "/v1/quiz-content",
    getQuizContents: "/v1/quiz-content",

    // Messages
    getMessages: "/v1/message/conversations",
    fetchConversation: "/v1/message/conversation",
    sendMessage: "/v1/message",
    forwardMessage: "/v1/message/forward",
    readMessageThread: "/v1/message/read",
    deleteMessage: '/v1/message',

    // permissions
    getPermissions: "/v1/permission/list",
    togglePermission: "/v1/permission/toggle",
  },
};
